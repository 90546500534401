<template>
  <div class="app-s-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="app-s-title"
          v-lazy="require('../../../assets/images/title4.png')"
        />
        <div class="app-s-d1">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div
                class="p-item"
                align="center"
              >
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span
                  class="s1"
                  v-html="item.name"
                ></span>
                <span
                  class="s2"
                  v-html="item.remark"
                >
                </span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ApplicationScenario-pt",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon1.png"),
          name: "为生产经营者提供</br>数据追溯体系",
          remark: `
                  帮助生产企业、流通经营企业</br>
                  终端零售企业等供应链各环节</br>
                  实现追溯可视化，不断完善</br>
                  产品追溯体系，确保产品来源可查</br>
                  去向可追，有效控制产品安全风险
          `,
        },
        {
          icon: require("../../../assets/images/app-icon2.png"),
          name: "为消费者提供</br>信息查询渠道",
          remark: `
                  方便消费者溯源产品信息</br>
                  同时作为精准触达媒介</br>
                  实现营销信息的高效传播</br>
                  以及一手市场信息的收集沉淀   
          `,
        },
        {
          icon: require("../../../assets/images/app-icon3.png"),
          name: "为监管部门提供</br>数据支持",
          remark: `
                  为各级政府的市场监管提供</br>
                  数据资源支持，为重要产品溯源</br>
                  体系规划提供专业咨询；</br>
                  协助建立产品追溯标准体系   
          `,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.app-s-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.app-s-title {
  display: block;
  width: 7rem;
  margin-top: 2.4rem;
}
.app-s-d1 {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -3rem;
    li {
      float: left;
      margin-left: 3rem;
      margin-top: 1rem;
      .p-item {
        width: 210px;
        height: 300px;
        background: #f3f3f3;
        .m1 {
          width: 54px;
          margin-top: 1rem;
        }
        .s1 {
          display: block;
          font-size: 14px;
          color: #6aa5ff;
          margin-top: 0.1rem;
        }
        .s2 {
          display: block;
          font-size: 12px;
          color: #aaaaaa;
          line-height: 2;
          margin-top: 14px;
        }
      }
    }
  }
}
</style>
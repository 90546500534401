import { render, staticRenderFns } from "./ApplicationScenario.vue?vue&type=template&id=60589a84&scoped=true&"
import script from "./ApplicationScenario.vue?vue&type=script&lang=js&"
export * from "./ApplicationScenario.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationScenario.vue?vue&type=style&index=0&id=60589a84&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60589a84",
  null
  
)

export default component.exports